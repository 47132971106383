import React from 'react'

const UploadIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.75 18H18.375C19.6679 18 20.9079 17.4864 21.8221 16.5721C22.7364 15.6579 23.25 14.4179 23.25 13.125C23.25 11.8321 22.7364 10.5921 21.8221 9.67785C20.9079 8.76361 19.6679 8.25 18.375 8.25H18.1875C17.6225 6.99683 16.6677 5.95984 15.4653 5.29366C14.2628 4.62748 12.8773 4.3678 11.5153 4.55334C10.1532 4.73888 8.88765 5.35971 7.90724 6.32324C6.92684 7.28677 6.28414 8.54138 6.07498 9.9C5.51187 9.74455 4.92216 9.71047 4.34489 9.80003C3.76762 9.88959 3.21596 10.1007 2.72642 10.4195C2.23688 10.7383 1.82063 11.1574 1.50522 11.6491C1.18981 12.1408 0.982441 12.6939 0.896831 13.2718C0.811221 13.8496 0.849327 14.4391 1.00863 15.0011C1.16792 15.5632 1.44478 16.0849 1.82088 16.5319C2.19697 16.979 2.66371 17.341 3.19022 17.5941C3.71673 17.8471 4.29098 17.9855 4.87498 18H8.24998" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15 14.25L12 11.25L9 14.25M12 19.5V11.25V19.5Z" stroke="#1E1E1E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
)

export default UploadIcon
