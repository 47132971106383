/** @jsx jsx */
/**
 * Items used to compose Toolbars
 */
import { useState } from 'react'
import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import NewMinutesIcon from 'assets/icons/NewMinutesIcon'
import { ColorBaseMulberryBlue, ColorBaseWhite, ColorEastBay } from 'assets/styles/variables'
import { jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, Button, makeStyles } from '@material-ui/core'
import { AIGenerationIcon } from 'assets/icons/AiGenerationIcon'
import { AtlasTooltip } from 'components/atlas-components/atlas-tooltip/Tooltip'

export const Separator = styled('div')`
    height: 70%;
    border-right: 2px solid #e0e0e0;
    margin-right: 10px;
    margin-left: 10px;
`

export const AddSectionButton = ({
    showLabels,
    onClick
}: {
    showLabels: boolean
    onClick?: () => void
}) => {
    const classes = useStyles()
    return (
        <Button
            disableFocusRipple
            disableRipple
            className={classes.buttonStyle}
            onClick={onClick}
            data-analytics="MM-AddSection"
            aria-label={i18n.t('ADD_SECTION_ITEM')}>
            {showLabels && translate('ADD_SECTION_ITEM')}
        </Button>
    )
}
export const AddActionButton = ({
    showLabels,
    onClick
}: {
    showLabels: boolean
    onClick?: () => void
}) => {
    const classes = useStyles()
    return (
        <Button
            disableFocusRipple
            disableRipple
            className={classes.buttonStyle}
            onClick={onClick}
            aria-haspopup="dialog"
            data-analytics="MM-AddAction"
            aria-label={i18n.t('ADD_ACTION_ITEM')}>
            {showLabels && translate('ADD_ACTION_ITEM')}
        </Button>
    )
}

export const AddUploadButton = ({
    showLabels,
    onClick = () => {},
    summarizationEnabled
}: {
    showLabels: boolean
    onClick?: () => void
    summarizationEnabled: boolean
}) => {
    const classes = useStyles()
    const [isFocused, setIsFocused] = useState(false)
    const [isActive, setIsActive] = useState(false)
    return (
        <AtlasTooltip
            title={
                summarizationEnabled
                    ? i18n.t('AI_TTM_BUTTON') || 'Default Tooltip Message'
                    : i18n.t('AI_NO_ACCESS_ERROR_MESSAGE').toString()
            }
            placement="top-start"
            PopperProps={{
                style: { maxWidth: '230px' }
            }}>
            <Box
                style={{
                    minWidth: '20px',
                    cursor: summarizationEnabled ? 'pointer' : 'not-allowed'
                }}>
                <Button
                    disableFocusRipple
                    disableRipple
                    className={classes.transcriptButton}
                    onClick={onClick}
                    aria-haspopup="dialog"
                    data-analytics="MM-IMPORT_TRANSCRIPT"
                    aria-label={i18n.t('AI_TTM_IMPORT_TRANSCRIPT')}
                    disabled={!summarizationEnabled}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onMouseDown={() => setIsActive(true)}
                    onMouseUp={() => setIsActive(false)}
                    style={{
                        border: summarizationEnabled
                            ? '2px solid transparent'
                            : `1px solid #e6e6e6`,
                        color: summarizationEnabled ? '#455D82' : '#B3B3B3',
                        background: summarizationEnabled
                            ? `linear-gradient(128deg, var(--Color-Brand-Primary-Red-2, rgba(211, 34, 42, 0.10)) 17.49%, var(--Color-Data-Visualization-Qualitative-Purple-01, rgba(194, 71, 250, 0.10)) 70.3%, var(--Color-Data-Visualization-Qualitative-Purple-04, rgba(77, 29, 213, 0.10)) 100%)`
                            : 'transparent',
                        boxShadow:
                            isFocused || isActive
                                ? `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`
                                : 'none',
                        borderRadius: isFocused || isActive ? '2px' : '0px'
                    }}>
                    <AIGenerationIcon fillColor={!summarizationEnabled ? '#B3B3B3' : undefined} />
                    {showLabels && translate('AI_TTM_IMPORT_TRANSCRIPT')}
                </Button>
            </Box>
        </AtlasTooltip>
    )
}

export const NewMinutesButton = ({
    isCollapsed,
    onAddMinutes
}: {
    isCollapsed?: boolean
    onAddMinutes: () => void
}) => {
    const classes = useStyles()
    return (
        <Button
            disableFocusRipple
            disableRipple
            className={classes.buttonStyle}
            onClick={onAddMinutes}>
            <NewMinutesIcon />
            {!isCollapsed && translate('ADD_NEW')}
        </Button>
    )
}

const useStyles = makeStyles(() => ({
    buttonStyle: {
        textTransform: 'none',
        cursor: 'pointer',
        maxWidth: '235px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        padding: '8px 12px 8px 12px',
        '@media (max-width: 980px)': {
            minWidth: '40px'
        },
        border: '2px solid transparent',
        borderRadius: '2px',
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        },
        '&:hover': {
            backgroundColor: '#364262'
        },

        height: '40px',
        color: '#fff',
        backgroundColor: ColorEastBay,
        fontSize: '16px'
    },
    transcriptButton: {
        display: 'flex',
        textTransform: 'none',
        maxWidth: '235px',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'center',
        alignItems: 'center',
        padding: '8px 12px 8px 12px',
        '@media (max-width: 980px)': {
            minWidth: '40px'
        },
        borderRadius: '2px',
        '&:disabled': {
            backgroundColor: 'transparent',
            opacity: '1'
        },
        height: '40px',
        fontSize: '16px',
        opacity: 1,
        '& span': {
            display: 'flex',
            gap: '8px'
        }
    },
    newMinutesIcon: {
        height: '1.2rem',
        width: '1.2rem',
        fill: 'white',
        marginRight: '4px'
    }
}))
