import React from 'react'
import './Toast.css'
import SuccessIcon from 'assets/icons/SuccessIcon'
import ToastErrorIcon from 'assets/icons/ToastErrorIcon'
import WarningAlertIcon from 'assets/icons/WarningAlertIcon'
import i18n from 'common/util/internationalization'
import applicationRouter from 'businesslayer/applicationRouter'
import {
    updatePregenerationAIMinutes,
    updateTranscriptAIMinutes
} from 'businesslayer/networkrequests'
import { PREGENERATION_DOCUMENT_STATUS } from 'components/minutetaker/components/InvitationView/types'
import { translate } from 'components/shared/internationalization'

import styled from '@emotion/styled'

const HelpCentre = styled('a')`
    color: #455d82;
    font-weight: 600;
    text-decoration: none;
`

const TranscriptToast = ({
    id,
    preGenPollingStatus,
    statusMessage,
    minuteInitiateUser,
    loggedInUser,
    MinutesTitle,
    removeToast
}) => {
    const status_Message = i18n.t(statusMessage)

    return (
        <>
            {minuteInitiateUser === loggedInUser ? (
                <div className={`custom-toast-ttm ${preGenPollingStatus}`}>
                    <div className="custom-toast-header">
                        <span className="custom-toast-icon">
                            {preGenPollingStatus ===
                            PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ? (
                                <ToastErrorIcon />
                            ) : preGenPollingStatus === PREGENERATION_DOCUMENT_STATUS.FAILED ? (
                                <ToastErrorIcon />
                            ) : preGenPollingStatus ===
                              PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH ? (
                                <ToastErrorIcon />
                            ) : preGenPollingStatus ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ? (
                                <WarningAlertIcon />
                            ) : (
                                <SuccessIcon />
                            )}
                        </span>
                        <div style={{ flexDirection: 'column' }}>
                            <div className={`custom-toast-title-ttm ${preGenPollingStatus}`}>
                                {MinutesTitle}
                            </div>
                            <div className="custom-toast-message">
                                {status_Message}
                                <div>
                                    {(preGenPollingStatus ===
                                        PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ||
                                        preGenPollingStatus ===
                                            PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH) && (
                                        <HelpCentre href={`https://www.google.com/`} target="blank">
                                            {i18n.t('HELP_CENTRE')}
                                        </HelpCentre>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="custom-toast-border" />
                    <div className="custom-toast-buttons">
                        <button
                            className="dismiss"
                            onClick={() => {
                                updateTranscriptAIMinutes(id, preGenPollingStatus, false)
                                removeToast(id)
                            }}>
                            {translate('TOAST_DISMISS_BUTTON')}
                        </button>
                        <button
                            className="action"
                            onClick={() => {
                                updateTranscriptAIMinutes(id, preGenPollingStatus, false)
                                applicationRouter.navigateTo(`/taker/${id}`)
                            }}>
                            {preGenPollingStatus === PREGENERATION_DOCUMENT_STATUS.FAILED ||
                            preGenPollingStatus === PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH
                                ? translate('BACK_TO_EDITOR')
                                : translate('VIEW')}
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    )
}

const PregenerationToast = ({
    id,
    preGenPollingStatus,
    statusMessage,
    minuteInitiateUser,
    loggedInUser,
    MinutesTitle,
    removeToast
}) => {
    const status_Message = i18n.t(statusMessage)

    return (
        <>
            {minuteInitiateUser === loggedInUser ? (
                <div className={`custom-toast ${preGenPollingStatus}`}>
                    <div className="custom-toast-header">
                        <span className="custom-toast-icon">
                            {preGenPollingStatus ===
                            PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ? (
                                <ToastErrorIcon />
                            ) : preGenPollingStatus === PREGENERATION_DOCUMENT_STATUS.FAILED ? (
                                <ToastErrorIcon />
                            ) : preGenPollingStatus ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ? (
                                <ToastErrorIcon />
                            ) : (
                                <SuccessIcon />
                            )}
                        </span>
                        <div style={{ flexDirection: 'column' }}>
                            <div className={`custom-toast-title ${preGenPollingStatus}`}>
                                {MinutesTitle}
                            </div>
                            <div className="custom-toast-message">{status_Message}</div>
                        </div>
                    </div>
                    <hr className="custom-toast-border" />
                    <div className="custom-toast-buttons">
                        <button
                            className="dismiss"
                            onClick={() => {
                                updatePregenerationAIMinutes(id, preGenPollingStatus, false)
                                removeToast(id)
                            }}>
                            {translate('TOAST_DISMISS_BUTTON')}
                        </button>
                        <button
                            className="action"
                            onClick={() => {
                                updatePregenerationAIMinutes(id, preGenPollingStatus, false)
                                applicationRouter.navigateTo(`/taker/${id}`)
                            }}>
                            {translate('VIEW')}
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    )
}

const StatusToast = (props) => {
    return props.forTranscript ? <TranscriptToast {...props} /> : <PregenerationToast {...props} />
}

export default StatusToast
