import i18n from 'common/util/internationalization'
import { getSessionStorageItem, getLocalStorageItem } from 'businesslayer/minutesLocalStore'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { getPostHeaders } from 'common/util/requestUtils'

export const fetchFileUploadResponse = async (minutesDocumentId: string, transcript: File[]) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')

    if (!currentCommitteeId) {
        return Promise.reject(i18n.t('YOU_MUST_BE_LOGGED_IN'))
    }
    const platform = transientStorageManager.platform
    const siteName = transientStorageManager.siteName

    const formData = new FormData()

    if (platform) {
        formData.append('platform', platform)
    }
    formData.append('site_name', siteName)

    if (Array.isArray(transcript) && transcript.length > 0) {
        transcript.forEach((file) => {
            formData.append(`transcripts[]`, file)
        })
    }

    let sessionId = getLocalStorageItem('sessionId')

    try {
        const response = await fetch(
            `/api/committees/${currentCommitteeId}/minutes_documents/${minutesDocumentId}/transcripts`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${sessionId}`
                },
                body: formData
            }
        )

        return await response.json()
    } catch (error) {
        console.error('Error uploading TRANSCRIPT:', error)
        return null
    }
}

export const fetchFileResponse = async (minutesDocumentId: string) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    let sessionId = getLocalStorageItem('sessionId')

    try {
        const response = await fetch(
            `/api/committees/${currentCommitteeId}/minutes_documents/${minutesDocumentId}/transcripts`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            }
        )

        if (!response.ok) {
            throw new Error(i18n.t('TRANSCRIPT_DELETE_ERROR'))
        }

        return await response.json()
    } catch (error) {
        console.error('Error deleting TRANSCRIPT:', error)
        throw new Error(i18n.t('TRANSCRIPT_DELETE_ERROR'))
    }
}

export const fetchFileDeleteResponse = async (minutesDocumentId: string, transcriptId: string) => {
    const currentCommitteeId = getSessionStorageItem('currentCommitteeId')
    let sessionId = getLocalStorageItem('sessionId')

    try {
        const response = await fetch(
            `/api/committees/${currentCommitteeId}/minutes_documents/${minutesDocumentId}/transcripts/${transcriptId}`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            }
        )

        if (!response.ok) {
            throw new Error(i18n.t('TRANSCRIPT_DELETE_ERROR'))
        }

        return await response
    } catch (error) {
        console.error('Error deleting TRANSCRIPT:', error)
        throw new Error(i18n.t('TRANSCRIPT_DELETE_ERROR'))
    }
}

export const generateTranscript = async (
    minutesDocumentId: any,
    currentCommitteeId: any,
    body: any
) => {
    try {
        return fetch(
            `/api/committees/${currentCommitteeId}/minutes_documents/${minutesDocumentId}/transcripts/generate_minutes`,
            {
                method: 'POST',
                credentials: 'include',
                headers: getPostHeaders(),
                body
            }
        ).then((response) => {
            if (response.status === 504) {
                throw new Error(i18n.t('GENERATE_TRANSCRIPT_ERROR'))
            }
            if (response.status === 401) {
                const message = i18n.t('SESSION_EXPIRED_MESSAGE')
                console.error(message)
                return Promise.reject(response)
            }
            return Promise.resolve()
        })
    } catch (error) {
        throw new Error(i18n.t('GENERATE_TRANSCRIPT_ERROR'))
    }
}
