import React from 'react'
import { Dialog, Divider, Button, Box } from '@material-ui/core'
import { ClassNames } from '@emotion/react'
import i18n from 'common/util/internationalization'
import { AIGenerationIcon } from 'assets/icons/AiGenerationIcon'
import { getLocalStorageItem } from 'businesslayer/minutesLocalStore'
import { ColorBaseMulberryBlue, ColorBaseWhite } from 'assets/styles/variables'
import {
    HeaderText,
    ProgressMessage,
    aiDialogContainer,
    aiDialogHeader,
    aiGenerateFooter,
    customButton,
    Link
} from '../PregeneratedMinutesStyles'
import { makeStyles } from '@material-ui/core/styles'
import { PREGENERATION_DOCUMENT_STATUS } from '../../components/InvitationView/types'

const useStyles = makeStyles({
    myButton: {
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    }
})

interface AiGenerationCompletedProps {
    updateGeneration: (...args: any[]) => void
    pregeneratedMinutes: any
    pregenerationStatus: any
}

const AiGenerationCompleted: React.FC<AiGenerationCompletedProps> = (props) => {
    const classes = useStyles(props)
    const userLanguage = getLocalStorageItem('userLanguage')
    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    open={
                        (props.pregeneratedMinutes || props.pregenerationStatus) ===
                        PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                    }
                    className={css`
                        .MuiPaper-root.MuiDialog-paper {
                            max-width: 450px;
                            max-height: 100%;
                            border-radius: 8px;
                        }
                    `}>
                    <div className={aiDialogContainer}>
                        <div className={aiDialogHeader}>
                            <AIGenerationIcon />
                            <HeaderText>{i18n.t('MINUTES_READY')}</HeaderText>
                        </div>
                        <Divider />
                        <ProgressMessage>
                            <span style={{ paddingBottom: '16px', display: 'inline-block' }}>
                                {i18n.t('MINUTES_READY_ALT')}
                            </span>
                            <Link
                                href={`https://help.diligent.com/diligenthelp/s/article/Data-processing-limitations-and-encryption-with-Diligent-AI?language=${userLanguage}`}
                                target="blank">
                                {i18n.t('LEARN_MORE')}
                            </Link>
                        </ProgressMessage>
                        <Divider />
                        <Box className={aiGenerateFooter}>
                            <Button
                                disableRipple
                                disableFocusRipple
                                className={`${classes.myButton} ${customButton}`}
                                style={{ backgroundColor: '#455D82', color: '#ffffff' }}
                                onClick={() => props.updateGeneration('acknowledged')}
                                data-analytics="MM-AI-PreGenerated-View">
                                {i18n.t('VIEW')}
                            </Button>
                        </Box>
                    </div>
                </Dialog>
            )}
        </ClassNames>
    )
}

export default AiGenerationCompleted
