import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

//#region Components
import styled from '@emotion/styled'
import { ClassNames } from '@emotion/react'

import AttendeesSection from './MinutesReviewAttendeesSection'
import { List } from 'components/shared/StyledComponents'

import { ColorBaseWhite } from 'assets/styles/variables'

import MinutesReviewEditorListItem from './MinutesReviewEditorListItem'
import { DocumentDetailsLayout } from 'assets/styles/LayoutConstants'
import DecoupledEditor from '@minutes/editor'
import { CKEditorContext } from '@ckeditor/ckeditor5-react'
import { tokenEndpoint } from 'businesslayer/api/review'
import minuteTakerSelectors from 'selectors/minuteTakerSelectors'
import { connect } from 'react-redux'
import { INVITEE_CONSTANTS, EDITOR_TABS } from '../components/InvitationView/types'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { Box, makeStyles, Typography } from '@material-ui/core'
import InfoIcon from 'assets/icons/InfoIcon'
import i18n from 'common/util/internationalization'
import { AddUploadButton } from 'components/shared/Toolbar/ToolbarElements'
import ListSubheader from '@material-ui/core/ListSubheader'
import { ToolbarCss, ToolbarBoxCss, listSubheader } from '../sectionlist/PregeneratedMinutesStyles'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'

const EditorListWrapper = styled('div')`
    background-color: ${ColorBaseWhite};
    min-width: 100%;
    max-width: 100%;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
`

//#region Props

type Props = {
    minutesId: string
    attendees: any[]
    reviewers: any[]
    minutesSections: any[]
    actionItems: ActionItem[]
    onActionReorder: any
    onActionChecked: any
    onEditAction: any
    onDeleteAction: any
    onViewAllActions: any
    isAllExpanded: boolean
    role: UserRole
    reviewComplete?: boolean
    loading?: boolean
    onEditorChange: (sectionId: string, data: string) => void
    currentMinuteItem: any
    currentSelectedTab: any
    showSideBar: boolean
    setIsLoading: any
    reviewCompleted?: boolean
    isInvited?: boolean
}

const useStyles = makeStyles({
    warningBox: {
        height: '50px',
        padding: '4px 8px',
        borderRadius: '4px',
        border: '1px solid #455D82',
        gap: '8px',
        display: 'flex',
        margin: '10px 0',
        width: 'fit-content'
    },
    warningIcon: {
        placeSelf: 'center',
        padding: '10px'
    },
    warningText: {
        color: '#000',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    warningTextContainer: {
        gap: '4px',
        display: 'grid'
    }
})

//#endregion

//#region Implementation
export const MinutesReviewEditorList = (props: Props) => {
    const [selectedSection, setSelectedSection] = useState<number | null>(null)
    const { _env_ } = window as any
    const [isLayoutReady, setIsLayoutReady] = useState<boolean>(false)

    const presenceListElementRef = useRef<HTMLDivElement>(null)

    const [token, setToken] = useState<string>('')
    const [contextEditor, setContextEditor] = useState()

    const classes = useStyles()

    const WarningElement = useMemo(
        () => (
            <Box display="flex" justifyContent="center">
                <Box className={classes.warningBox}>
                    <Box className={classes.warningIcon}>
                        <InfoIcon />
                    </Box>
                    <Box className={classes.warningTextContainer}>
                        <Typography className={classes.warningText}>
                            {i18n.t('REVIEW_SUBMITTED')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        ),
        [classes]
    )

    const fetchCollaborationToken = useCallback(async () => {
        const response = await tokenEndpoint(props.minutesId)
        setToken(response.collaboration_token)
    }, [props.minutesId])

    useEffect(() => {
        fetchCollaborationToken()
    }, [fetchCollaborationToken])

    useEffect(() => {
        if (token) {
            setIsLayoutReady(true)
        }
    }, [token])

    const onContextReady = (context: any) => {
        if (context && context !== context?.current) {
            context.current = context
            setContextEditor(context)
        }
    }

    const contextError = useCallback((e) => {
        console.log(e)
    }, [])

    const contextConfig = {
        cloudServices: {
            tokenUrl: () =>
                new Promise((resolve, reject) => {
                    try {
                        return resolve(token)
                    } catch (e) {
                        reject(e)
                    }
                }),
            webSocketUrl: _env_.CKEDITOR_SOCKET_URL,
            bundleVersion: '35.4.0',
            connectionTimeout: 60,
            requestTimeout: 60
        },
        collaboration: {
            channelId: `in_review_context_channel`
        },
        presenceList: {
            container: presenceListElementRef.current
        }
    }

    const isEditorsOpen = isLayoutReady && contextEditor && presenceListElementRef.current

    const renderSectionsList = (minutesSections) => {
        const sectionsElements = minutesSections.map((section, i) => {
            if (
                section.sectionType === INVITEE_CONSTANTS.ATTENDEES &&
                props.currentSelectedTab === EDITOR_TABS.ATTENDEES
            )
                return (
                    <AttendeesSection
                        key={section.id}
                        section={section}
                        attendees={props.attendees}
                        attendeesInfo={section.jsonBody}
                        isAllExpanded={props.isAllExpanded}
                    />
                )
            else if (
                section.sectionType !== INVITEE_CONSTANTS.ATTENDEES &&
                props.currentSelectedTab === EDITOR_TABS.EDITOR
            ) {
                return (
                    <MinutesReviewEditorListItem
                        key={section.id}
                        loading={props.loading}
                        autoFocus={i === 1}
                        minutesId={props.minutesId}
                        section={section}
                        isSelected={selectedSection === section.id}
                        isAllExpanded={props.isAllExpanded}
                        onSectionSelect={(id) => setSelectedSection(id)}
                        actionItems={props.actionItems}
                        onActionReorder={props.onActionReorder}
                        onActionChecked={props.onActionChecked}
                        onEditAction={props.onEditAction}
                        onDeleteAction={props.onDeleteAction}
                        onViewAllActions={props.onViewAllActions}
                        reviewers={props.reviewers}
                        onEditorChange={props.onEditorChange}
                        role={props.role}
                        reviewComplete={props.reviewComplete}
                        showSideBar={props.showSideBar}
                        setIsLoading={props.setIsLoading}
                    />
                )
            } else {
                return null
            }
        })

        return (
            <>
                {token && (
                    <CKEditorContext
                        context={DecoupledEditor.Context}
                        id="inReviewContext"
                        isLayoutReady={isLayoutReady}
                        onReady={onContextReady}
                        onError={contextError}
                        config={contextConfig}>
                        <div>{isEditorsOpen && sectionsElements}</div>
                    </CKEditorContext>
                )}
                <div ref={presenceListElementRef} style={{ display: 'none' }} />
                {/* we need  presence list to achieve one connection for all the editors but we dont need to display presence list  in Minutes */}
            </>
        )
    }

    const allSections = renderSectionsList(props.minutesSections)
    return (
        <ClassNames>
            {() => (
                <Container style={{ overflow: 'visible' }} data-testid="review-container">
                    {props.role === 'DIRECTOR' && props.reviewComplete && WarningElement}
                    <ListWrapper style={{ overflow: 'visible' }} data-testid="list-wrapper-ckedior">
                        <List
                            style={{
                                overflow: 'visible'
                            }}
                            data-testid="list-ckedior">
                            {props.role === 'ADMIN' && props.minutesSections?.length > 1 && (
                                <EditorListWrapper>
                                    <ListSubheader className={listSubheader}>
                                        <Box className={ToolbarBoxCss}>
                                            <Box
                                                className={ToolbarCss}
                                                role="toolbar"
                                                aria-label="Editor Toolbar">
                                                {transientStorageManager.book_summarization_enabled !==
                                                    null && (
                                                    <AddUploadButton
                                                        showLabels={true}
                                                        summarizationEnabled={false}
                                                        aria-label={i18n.t(
                                                            'AI_TTM_IMPORT_TRANSCRIPT'
                                                        )}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    </ListSubheader>
                                </EditorListWrapper>
                            )}
                            {allSections}
                        </List>
                    </ListWrapper>
                    <Footer />
                </Container>
            )}
        </ClassNames>
    )
}
export const Progress = ({ isLoading }: { isLoading: boolean }) => {
    return <LinearIndeterminate loading={isLoading} />
}

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: minuteTakerSelectors.currentMinuteItem(state.minuteTakerReducer),
        currentSelectedTab: minuteTakerSelectors.currentSelectedTab(state.minuteTakerReducer),
        showSideBar: minuteTakerSelectors.showSideBar(state.minuteTakerReducer)
    }
}

const DOCUMENT_MAX_WIDTH = DocumentDetailsLayout.document.maxWidth
const Container = styled.div`
    overflow-y: auto;
    color: #131e29;
    padding: 0;
    max-width: 100vw;
    overflow-x: auto;
    overflow-y: auto;
    max-height: calc(100% - 30px);
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        max-height: calc(100% - 70px);
    }
`
const ListWrapper = styled.div`
    display: flex;
    flex: 1 1 ${DOCUMENT_MAX_WIDTH}px;
    background-color: ${ColorBaseWhite};
    overflow-y: auto;
    margin: 2px auto;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
`

const Footer = styled('div')`
    background-color: transparent;
    // height: 48px;
    border-top: 1px solid #e6e6e6;
`
// const wrapperSidebar = css`// to do
//     height: 100%;
//     min-width: 60vw;
//     overflow-x: hidden;
//     @media only screen and (min-width: 768px) and (max-width: 1024px) {
//         width: 100vw;
//         margin: auto;
//         height: 100%;
//     }
// `

export default connect(mapStateToProps)(MinutesReviewEditorList)
//#endregion
