import styled from '@emotion/styled'
import { TrashCanDeleteIcon as DeleteUploadedFileIcon } from 'assets/icons/TrashCanDeleteIcon'
import SuccessIcon from 'assets/icons/SuccessIcon'
import { ColorEastBay, ColorBaseLightRed } from 'assets/styles/variables'

export const UploadContainer = styled('div')`
    background: rgba(244, 246, 248, 1);
    border: 1px solid rgba(230, 230, 230, 1);
    padding: 24px;
    text-align: center;
`;

export const UploadInput = styled('input')`
    display: none;
`;

export const UploadText = styled('p')`
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;

export const UploadLink = styled('a')`
    color: #385f99;
    text-decoration: underline;
`;

export const UploadInfo = styled('p')`
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #676767;
`;

export const UploadSection = styled('div')`
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    padding: 8px 8px 8px 16px;
`;

export const UploadIconStyle = styled('p')`
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    color: #676767;
`;

export const UploadFileDetails = styled('div')`
    display: flex;
    flex-direction: column;
`;

export const HighlightedText = styled('span')`
    font-weight: 600;
`;

export const StyledDeleteSectionIcon = styled(DeleteUploadedFileIcon)`
    fill: ${ColorEastBay};
    width: 21px;
    height: 24px;
    margin-left: auto;
`;

export const StyledSuccessIcon = styled(SuccessIcon)`
    width: 24px;
    height: 24px;
`;

export const UploadFileSize = styled('div')`
    font-weight: 400;
`;

export const UploadFileError = styled('div')`
    font-weight: 400;
    color: ${ColorBaseLightRed};
`;
