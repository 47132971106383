import React from 'react'
import { Button, Box } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import {
    aiGeneratedNotification,
    aiGeneratedNotificationBody,
    aiGeneratedNotificationContent,
    btnDismiss,
    Link
} from '../sectionlist/PregeneratedMinutesStyles'
import { getLocalStorageItem } from 'businesslayer/minutesLocalStore'
import InfoIcon from 'assets/icons/InfoIcon'

interface TtmGeneratedNotificationProps {
    updateTranscriptGeneration: (status: string) => void
    dismissAiNotificationBar: () => void
}

const TtmGeneratedNotification: React.FC<TtmGeneratedNotificationProps> = (props) => {
    const userLanguage = getLocalStorageItem('userLanguage')
    return (
        <Box className={aiGeneratedNotification}>
            <Box className={aiGeneratedNotificationBody}>
                <Box className={aiGeneratedNotificationContent}>
                    <InfoIcon />
                    <span>
                        {i18n.t('AI_GENERATED_CONTENT')}
                        <Link
                            style={{ textDecoration: 'none' }}
                            href={`https://help.diligent.com/diligenthelp/s/article/Data-processing-limitations-and-encryption-with-Diligent-AI?language=${userLanguage}`}
                            target="blank">
                            {i18n.t('LEARN_MORE')}
                        </Link>
                    </span>
                </Box>
                <Button
                    key={'DismissAiGeneratedContentNotification'}
                    className={btnDismiss}
                    onClick={() => {
                        props.dismissAiNotificationBar()
                        props.updateTranscriptGeneration('acknowledged')
                    }}
                    disableFocusRipple
                    disableRipple
                    data-testid="AI_GENERATED_CONTENT_DISMISS_BUTTON">
                    {i18n.t('TOAST_DISMISS_BUTTON')}
                </Button>
            </Box>
        </Box>
    )
}

export default TtmGeneratedNotification
