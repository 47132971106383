import React from 'react'
import { Dialog, Divider, Button, Box } from '@material-ui/core'
import { ClassNames } from '@emotion/react'
import i18n from 'common/util/internationalization'
import { AIGenerationIcon } from 'assets/icons/AiGenerationIcon'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { ColorBaseMulberryBlue, ColorBaseWhite } from 'assets/styles/variables'
import {
    HeaderText,
    ProgressMessage,
    aiDialogContainer,
    aiDialogHeader,
    aiGenerateFooter,
    customButton
} from '../sectionlist/PregeneratedMinutesStyles'
import { makeStyles } from '@material-ui/core/styles'
import { PREGENERATION_DOCUMENT_STATUS } from '../components/InvitationView/types'

const useStyles = makeStyles({
    myButton: {
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    }
})

interface TtmGenerateDialogProps {
    updateTranscriptGeneration: (...args: any[]) => void
    updateNavigateOut: (...args: any[]) => void
    transcriptedMinutes: any
}

const TtmGenerateDialog: React.FC<TtmGenerateDialogProps> = (props) => {
    const classes = useStyles(props)
    return (
        <ClassNames>
            {({ css }) => (
                <Dialog
                    open={props?.transcriptedMinutes === PREGENERATION_DOCUMENT_STATUS.PROGRESS}
                    className={css`
                        .MuiPaper-root.MuiDialog-paper {
                            max-width: 1000px;
                            width: 1000px;
                            max-height: 100%;
                            border-radius: 8px;
                        }
                    `}>
                    <div className={aiDialogContainer}>
                        <div className={aiDialogHeader}>
                            <AIGenerationIcon />
                            <HeaderText>{i18n.t('AI_TTM_MINUTES')}</HeaderText>
                        </div>
                        <Divider />
                        <ProgressMessage>
                            {i18n.t('AI_TTM_TRANSFORM_SUBTITLE')}
                            <br />
                            <br />
                            <LinearIndeterminate loading={true} />
                        </ProgressMessage>
                        <Divider />
                        <Box className={aiGenerateFooter}>
                            <Button
                                disableRipple
                                disableFocusRipple
                                className={`${classes.myButton} ${customButton}`}
                                style={{ color: '#455D82', border: '1px solid #455D82' }}
                                onClick={() => props.updateNavigateOut(true)}
                                data-analytics="MM-AI-Transcript-GoToListPage">
                                {i18n.t('NOT_FOUND_LINK')}
                            </Button>
                            <Button
                                disableRipple
                                disableFocusRipple
                                className={`${classes.myButton} ${customButton}`}
                                style={{ backgroundColor: '#455D82', color: '#ffffff' }}
                                onClick={() => props.updateTranscriptGeneration('canceled')}
                                data-analytics="MM-AI-Transcript-Cancel">
                                {i18n.t('STOP_GENERATING_BUTTON')}
                            </Button>
                        </Box>
                    </div>
                </Dialog>
            )}
        </ClassNames>
    )
}

export default TtmGenerateDialog
