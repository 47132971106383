import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')`
`

type Props = {
    title?: JSX.Element
    className?: string
}

const SuccessIcon = ({ className }: Props) => (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width="17px"
            height="17px"
            viewBox="0 0 16 16"
            preserveAspectRatio="xMidYMid meet"
            focusable="false"
            className={className}>
            <path
                fill="#05704B"
                fill-rule="evenodd"
                d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.293-11.332L6.75 9.21 4.707 7.168 3.293 8.582 6.75 12.04l5.957-5.957-1.414-1.414z"></path>
        </Svg>   
)

export default SuccessIcon
