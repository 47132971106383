import {
    deleteJSON,
    getOfficeDoc,
    getJSON,
    postJSON,
    putJSON,
    patchJSON,
    getOfficeXls
} from 'common/util/request'
import moment from 'moment-timezone'
import i18n from 'common/util/internationalization'
import {
    createReportPayload,
    serialize,
    serializeUnnormalized
} from 'businesslayer/networkrequests/minutes/serializer'
import { getOfficeXlsPost } from 'common/util/request'

export const getMinutesByCommittee = (committeeId, sordField, ascending) => {
    return getJSON(
        `committees/${committeeId}/minutes_documents?sort_by=${sordField}&sort_direction=${
            ascending ? 'asc' : 'desc'
        }&page=1&per_page=500`
    )
}

export const getMinutesDetail = (minutesId) => {
    return getJSON(`minutes_documents/fetchone/${minutesId}`)
}

export const deleteMinutes = (committeeId, minutesId) => {
    const result = deleteJSON(`committees/${committeeId}/minutes_documents/${minutesId}`).then(
        () => minutesId
    )

    return result
}

export const updatePregenerationAIMinutes = (minutesId, status, view_toast: boolean = false) => {
    const body = {
        data: {
            type: 'minutes_documents',
            attributes: {
                pregenerated_minutes: status,
                view_toast: view_toast
            }
        }
    }
    return patchJSON(`minutes_documents/${minutesId}/update_pregeneration_status`, { body })
}

export const updateTranscriptAIMinutes = (minutesId, status, view_toast: boolean = false) => {
    const body = {
        data: {
            type: 'minutes_documents',
            attributes: {
                transcript_status: status,
                view_toast: view_toast
            }
        }
    }
    return patchJSON(`minutes_documents/${minutesId}/update_transcript_status`, { body })
}

export const exportMinutes = (committeeId: string, minutesId: string, minutesOnly: boolean) => {
    const timeZone = encodeURIComponent(moment.tz.guess())
    const url =
        `committees/${committeeId}/minutes_documents/${minutesId}/export/` +
        `?minutes_only=${minutesOnly}` +
        `&timezone=${timeZone}` +
        `&include_page_numbers=true` +
        `&user_language=${i18n.language}`

    return getOfficeDoc(url)
}

export const saveMinutes = (committeeId, item) => {
    const jsonApiBody = serialize({ item, committeeId })

    if (item.duplicateOption) {
        const duplicateSectionsOnly = item.duplicateOption === 'sections_only'
        const url =
            `committees/${committeeId}/minutes_documents` +
            `?duplicate_sections_only=${duplicateSectionsOnly}` +
            `&duplicate_from_id=${item.id}`
        return postJSON(url, jsonApiBody)
    }

    return !!item.id
        ? putJSON(`committees/${committeeId}/minutes_documents/${item.id}`, jsonApiBody)
        : postJSON(
              `committees/${committeeId}/minutes_documents?user_language=${i18n.language}`,
              jsonApiBody
          )
}
export const saveMinutesProperties = (item: MinutesUnnormalized) => {
    const committeeId = item.committee.id
    const jsonApiBody = serializeUnnormalized(item as any)
    const options = { shouldNormalize: false }

    if (item.duplicateOption) {
        const duplicateSectionsOnly = item.duplicateOption === 'sections_only'
        return postJSON(
            `committees/${committeeId}/minutes_documents` +
                `?duplicate_sections_only=${duplicateSectionsOnly}` +
                `&duplicate_from_id=${item.id}`,
            jsonApiBody,
            options
        )
    }

    return !!item.id
        ? putJSON(`committees/${committeeId}/minutes_documents/${item.id}`, jsonApiBody, options)
        : postJSON(
              `committees/${committeeId}/minutes_documents?user_language=${i18n.language}`,
              jsonApiBody,
              options
          )
}

export const setMinuteStatus = (committeeId: string, minutesId: string, status: string) => {
    const jsonBody = JSON.stringify({ status: status })
    const url = `committees/${committeeId}/minutes_documents/${minutesId}/status`

    return putJSON(url, jsonBody)
}

export const saveNewRewReportProperties = (newReportData: CreateReportPayload) => {
    const jsnoApiBody = createReportPayload(newReportData)
    const options = { shouldNormalize: false }
    //executing post request and passing jsonapibody as payload
    return postJSON('v1/attendance_report', jsnoApiBody, options)
}

export const exportAttendanceReport = (_committeeId: string, reportData: any) => {
    const jsnoApiBody = createReportPayload(reportData)
    const url = 'v1/export_attendance_report'
    return getOfficeXlsPost(url, jsnoApiBody)
}

export const exportXlsList = (QueueApi) => {
    const timeZone = encodeURIComponent(moment.tz.guess())
    const url = `action_items/export_action_list?${QueueApi}&timezone=${timeZone}`
    return getOfficeXls(url)
}
