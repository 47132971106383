import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { css } from '@emotion/react'
import i18n from 'common/util/internationalization'
import { AtlasIcon } from 'web-components/atlas-icon'
import { ColorbaseSteelBlue, ColorBaseWhite } from 'assets/styles/variables'

const useStyles = makeStyles(() =>
    createStyles({
        paddingStyle: {
            paddingLeft: '0.5px'
        },
        formControl: {
            border: '1px solid #949494',
            borderRadius: '4px',
            padding: '4px',
            paddingLeft: '5px',
            width: '100%',
            fontFamily: 'Source Sans Pro',
            margin: '0px',
            maxWidth: '100%',
            '& .MuiInput-underline.MuiInput-underline.MuiInput-underline.MuiInput-underline:hover:before': {
                borderBottom: '0px',
                paddingLeft: '5px'
            }
        },
        menuItems: {
            '& .MuiCheckbox-colorSecondary.Mui-checked': {
                color: `${ColorbaseSteelBlue}`
            }
        },
        selectInner: {
            '&:after': {
                borderBottom: '0px',
                paddingLeft: '4px'
            },
            '& .MuiSelect-select:focus': {
                backgroundColor: `${ColorBaseWhite}`,
                outline: '2px solid #0774EE',
                borderRadius: '4px'
            },
            fontFamily: 'Source Sans Pro',
            '&:before': { borderBottom: '0px' }
        }
    })
)

export default function MultipleSelect({
    value,
    placeholder,
    helperText,
    name,
    onChange,
    'data-testid': data_testid,
    role,
    ariaLabelledby,
    variant,
    'data-analytics': dataAnalytics,
    ...props
}) {
    const classes = useStyles()
    const [selectedItems, setSelectedItems] = React.useState<string[]>([])
    const menuItems = [
        i18n.t('SELECT_ALL_PLACEHOLDER'),
        i18n.t('REVIEW_COMPLETED'),
        i18n.t('OVERDUE_ACTION_LIST_LABEL'),
        i18n.t('PENDING')
    ]

    const handleChange = (event) => {
        const { value } = event.target
        !!onChange && onChange(event)
        !!props.onBlur && props.onBlur(event as any)
        if (value.includes(i18n.t('SELECT_ALL_PLACEHOLDER').toLowerCase())) {
            // Select or unselect all items based on the presence of 'Select All' in the value
            if (selectedItems.length === menuItems.length - 1) {
                // If all items are selected, unselect all
                setSelectedItems([])
            } else {
                // If not all items are selected, select all
                setSelectedItems(menuItems.map((item) => item.toLowerCase()).slice(1))
            }
        } else {
            // Update the selected items based on user selection
            setSelectedItems(
                value.filter((item) => item !== i18n.t('SELECT_ALL_PLACEHOLDER').toLowerCase())
            )
        }
    }

    const isAllSelected = selectedItems.length === menuItems.length - 1

    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                displayEmpty={true}
                native={false}
                value={selectedItems}
                className={classes.selectInner}
                IconComponent={AtlasSelectIcon}
                style={{
                    width: '100%',
                    right: '7px',
                    paddingLeft: '13px'
                }}
                name={name}
                MenuProps={{
                    getContentAnchorEl: null, // Prevents dynamic repositioning
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }}
                onChange={handleChange}
                renderValue={(selected) => {
                    return (
                        (selected as string[])
                            .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
                            .join(', ') || 'Select all'
                    )
                }}>
                {menuItems.map((item) => (
                    <MenuItem className={classes.menuItems} key={item} value={item.toLowerCase()}>
                        <Checkbox
                            checked={isAllSelected || selectedItems.includes(item.toLowerCase())}
                        />
                        <ListItemText primary={item} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const AtlasSelectIcon = (props) => (
    <AtlasIcon
        name="expand-down"
        css={css`
            position: absolute;
            top: calc(50% - 12px);
            right: 7;
            margin-right: 14px;
            cursor: pointer;
        `}
        {...props}
    />
)
