import styled from '@emotion/styled'
import React from 'react'

const Svg = styled('svg')``

type Props = {
    title?: JSX.Element
    className?: string
    fillColor?: string
}

export const AIGenerationIcon = ({ className, fillColor }: Props) => (
    <Svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.75 9L19 6.5L21.5 5.25L19 4L17.75 1.5L16.5 4L14 5.25L16.5 6.5L17.75 9ZM11.585 9.415L9 4.25L6.415 9.415L1.25 12L6.415 14.585L9 19.75L11.585 14.585L16.75 12L11.585 9.415ZM16.5 17.5L17.75 15L19 17.5L21.5 18.75L19 20L17.75 22.5L16.5 20L14 18.75L16.5 17.5Z"
            fill={fillColor ? fillColor: "url(#paint0_linear_1599_64557)"}
        />
        <defs>
            <linearGradient
                id="paint0_linear_1599_64557"
                x1="2.18103"
                y1="8.5875"
                x2="21.0892"
                y2="23.0375"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#D3222A" />
                <stop offset="0.64" stop-color="#C247FA" />
                <stop offset="1" stop-color="#4D1DD5" />
            </linearGradient>
        </defs>
    </Svg>
)
