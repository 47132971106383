import React, { Component } from 'react'
import ReactDOM from 'react-dom'

//#region Components

import Attendees from './MinuteEditorAttendees'
import EditorListItem from './MinuteEditorListItem'
import { EditorToolbar, Toolbar } from '../../shared/Toolbar'
import Footer from './MinuteEditorListViewFooter'
import AutoScroller from './MinuteEditorListAutoScroller'

import DomHelper from '../../helpers/DomHelper'
import { deleteAction } from 'businesslayer/api/actions'
import { getMinutesDetail } from 'businesslayer/api/minutes'
import { getSections } from 'businesslayer/api/sections'
import {
    updatePregenerationAIMinutes,
    updateTranscriptAIMinutes
} from 'businesslayer/networkrequests'
import {
    fetchFileUploadResponse,
    fetchFileDeleteResponse,
    generateTranscript
} from 'businesslayer/networkrequests/transcriptService'
//#endregion

//#region Redux

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { actions } from '../../../reducers/minuteTakerReducer'
import { actions as actionEditorReducer } from 'reducers/actionEditorReducer'

import selectors from '../../../selectors/minuteTakerSelectors'
import styled from '@emotion/styled'
import { List, RootRef } from 'components/shared/StyledComponents'
import { ColorBaseWhite } from 'assets/styles/variables'
import { pathOr } from 'rambdax'
import ActionItemEditDialog from '../components/ActionItemEditDialog/ActionItemEditDialog'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
    AddActionButton,
    AddSectionButton,
    AddUploadButton
} from 'components/shared/Toolbar/ToolbarElements'
import { DocumentToolbar } from 'components/shared/Toolbar/ComposedToolbars/DocumentToolbar'
import { Box } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import UtilityExpandCollapse from './UtilityExpandCollapse'
import ListSubheader from '@material-ui/core/ListSubheader'
import MinutesActions from '../components/MinutesActions'
import {
    EDITOR_TABS,
    DOCUMENT_STATUS,
    PREGENERATION_DOCUMENT_STATUS,
    TRANSCRIPT_UPLOAD_STATUS
} from '../components/InvitationView/types'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { getLocalStorageItem } from 'businesslayer/minutesLocalStore'
import { getPreGenerationStatus } from 'businesslayer/networkrequests/preGenerationStatusService'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import {
    ToastContainer,
    ToolbarCss,
    ToolbarBoxCss,
    listSubheader
} from './PregeneratedMinutesStyles'
import TtmUploadDialog from '../TranscriptToMinutes/TtmUploadDialog'
import TtmGenerateDialog from '../TranscriptToMinutes/TtmGenerateDialog'
import AiGenerateDialog from './PregeneratedMinutes/AiGenerateDialog'
import AiGenerationCompleted from './PregeneratedMinutes/AiGenerationCompleted'
import UtilityHandleToast from './UtilityHandleToast'
import TtmGeneratedNotification from '../TranscriptToMinutes/TtmGeneratedNotification'
//#endregion

//#region Styles

const headerHeight = 80

// const progressSize = 60
const progressContainerSize = 71
const toolbarSize = 56
const newToolbarSize = 80
const initialHeight = 640

const StyleWrapper = styled('div')<{ height: number }>`
    color: ${ColorBaseWhite};
    height: ${({ height }) => height}px;
`

const ListWrapper = styled('div')`
    background-color: ${ColorBaseWhite};
    min-width: 700px;
    max-width: 1090px;
    margin: 24px auto;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
`

//#endregion

//#region Props

type Props = {
    actions: {
        parseSectionData: (...args: any[]) => void
        addAction: () => void
        addSection: () => void
        loadSections: (...args: any[]) => void
        editAction: (...args: any[]) => void
        toggleExpandCollapseAll: (arg: boolean) => void
        removeAction: (...args: any[]) => void
        selectActionItem: (...args: any[]) => void
        // setGlobalFocusLockState: (...args: any[]) => void
        changeSectionOrder: (...args: any[]) => void
        loadActionsFulfilled: (...args: any[]) => void
        fetchMinuteItemFulfilled: (...args: any[]) => void
    }

    minutesSections: Array<any>
    currentMinuteItem: any
    selectedTabIndex: number
    actionItems: Array<any>
    isEditingAction: boolean
    currentSelectedTab: string
    onActionChecked: (...args: any[]) => void
    style: any
    setIsLoading: any
    onStatusChange: (status: string) => void
} & RouteComponentProps

//#endregion

//#region Implementation
//This is umprella component for sections.
//It also hosts tollbar with buttons and has some scroll management logic
class MinuteEditorListView extends React.PureComponent<Props> {
    private intervalId: ReturnType<typeof setTimeout> | null = null
    scrolledNewItems: Map<string, any> = new Map()
    allListNodes: Map<string, any> = new Map()

    lastListItem: Component | null = null
    list?: any
    isScrollingToLastItem: boolean = false
    state: {
        height: number
        isExpanded: boolean
        pregeneratedMinutes: string | null
        navigatingOut: boolean
        loaded: boolean
        pregenerationStatus: string
        minutesDocuments: any[]
        toasts: any[]
        committeeIDs: string[]
        uploadModal: boolean
        uploadStatus: string
        items: { name: string; size: number; file: File | null }
        transcriptId: string
        fileUploadError: string
        transcriptStatus: string
        transcriptedMinutes: string | null
        toastsTranscript: any[]
        showAiNotificationBar: boolean
    } = {
        height: initialHeight,
        isExpanded: true,
        pregeneratedMinutes: null,
        navigatingOut: false,
        loaded: false,
        pregenerationStatus: PREGENERATION_DOCUMENT_STATUS.PROGRESS,
        minutesDocuments: [],
        toasts: [],
        committeeIDs: [],
        uploadModal: false,
        uploadStatus: TRANSCRIPT_UPLOAD_STATUS.DEFAULT,
        items: { name: '', size: 0, file: null },
        transcriptId: '',
        fileUploadError: '',
        transcriptStatus: PREGENERATION_DOCUMENT_STATUS.PROGRESS,
        transcriptedMinutes: null,
        toastsTranscript: [],
        showAiNotificationBar: false
    }

    async componentDidMount() {
        this.setState({ committeeIDs: JSON.parse(getLocalStorageItem('committeeIds')) })
        await this.getPregeneratedMinutes(null, true)
        this.setState({ loaded: true })
        this.startPolling()
    }

    startPolling = () => {
        this.fetchMinutesDocuments()
        this.intervalId = setInterval(this.fetchMinutesDocuments, 20000)
    }

    stopPolling = () => {
        if (this.intervalId) {
            clearInterval(this.intervalId)
            this.intervalId = null
        }
    }

    componentWillUnmount() {
        this.stopPolling()
    }

    private removeToast = (id: string) => {
        this.setState({
            toasts: this.state.toasts.filter((toast: any) => toast?.id !== id),
            toastsTranscript: this.state.toastsTranscript.filter((toast: any) => toast?.id !== id)
        })
    }

    private checkForPregenStatus = (pregenStatus: string) => {
        if (
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.AUTOMATED &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.FAILED &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (pregenStatus === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR &&
                this.state.pregenerationStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS)
        ) {
            return true
        }
        return false
    }

    private checkForTranscriptStatus = (transcriptStatus: string) => {
        if (
            (transcriptStatus === PREGENERATION_DOCUMENT_STATUS.AUTOMATED &&
                this.state.transcriptStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (transcriptStatus === PREGENERATION_DOCUMENT_STATUS.FAILED &&
                this.state.transcriptStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (transcriptStatus === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS &&
                this.state.transcriptStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (transcriptStatus === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR &&
                this.state.transcriptStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS) ||
            (transcriptStatus === PREGENERATION_DOCUMENT_STATUS.COMPLETE_MISMATCH &&
                this.state.transcriptStatus === PREGENERATION_DOCUMENT_STATUS.PROGRESS)
        ) {
            return true
        }
        return false
    }

    fetchMinutesDocuments = async () => {
        try {
            const response = await getPreGenerationStatus(this.state.committeeIDs)
            const pregenDocumentsData = response?.data
            this.setState({ minutesDocuments: pregenDocumentsData })
            const hasPregenProgress = pregenDocumentsData.some(
                (item: any) =>
                    item.attributes.pregenerated_minutes === PREGENERATION_DOCUMENT_STATUS.PROGRESS
            )
            const result = pregenDocumentsData.find(
                (item: any) => item.id === this.props.currentMinuteItem?.id
            )
            const pregenStatus = result?.attributes?.pregenerated_minutes
            if (this.checkForPregenStatus(pregenStatus)) {
                if (
                    pregenStatus === PREGENERATION_DOCUMENT_STATUS.AUTOMATED ||
                    pregenStatus === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                ) {
                    this.props.actions.loadSections(
                        await getSections(this.props.currentMinuteItem?.id)
                    )
                }
                await this.getPregeneratedMinutes(pregenStatus)
                this.setState({ pregenerationStatus: pregenStatus })
                this.setState({ pregeneratedMinutes: pregenStatus })
            }

            const transcriptData = response?.transcript
            const hasTranscriptProgress = transcriptData.some(
                (item: any) =>
                    item.attributes.transcript_status === PREGENERATION_DOCUMENT_STATUS.PROGRESS
            )
            const transcriptResult = transcriptData.find(
                (item: any) => item.id === this.props.currentMinuteItem?.id
            )
            const transcriptStatus = transcriptResult?.attributes?.transcript_status
            if (this.checkForTranscriptStatus(transcriptStatus)) {
                if (
                    transcriptStatus === PREGENERATION_DOCUMENT_STATUS.AUTOMATED ||
                    transcriptStatus === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                ) {
                    this.props.actions.loadSections(
                        await getSections(this.props.currentMinuteItem?.id)
                    )
                }

                await this.getPregeneratedMinutes(transcriptStatus, true)
                this.setState({ transcriptStatus: transcriptStatus })
                this.setState({ transcriptedMinutes: transcriptStatus })
            } else {
                this.setState({ transcriptStatus: transcriptStatus })
                this.setState({ transcriptedMinutes: transcriptStatus })
            }

            if (!(hasPregenProgress || hasTranscriptProgress)) {
                // TODO: move to componentdidupdate
                if (this.intervalId) {
                    clearInterval(this.intervalId)
                    this.intervalId = null
                }
            }
            // Filter pregenDocumentsData to show only non 'progress' toasts
            const newToasts = pregenDocumentsData
                .filter(
                    ({ attributes }) =>
                        attributes.pregenerated_minutes !== PREGENERATION_DOCUMENT_STATUS.PROGRESS
                )
                .map(({ attributes }) => ({
                    id: `${attributes.document_id}`,
                    MinutesTitle:
                        attributes.pregenerated_minutes ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('AI_GENERATE_FAIL_TITLE')
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('MEETING_MINUTES_READY')
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_GENERATE_PARTIAL_FAIL_TITLE')
                            : i18n.t('AI_GENERATE_FAIL_TITLE'),
                    author_id: `${attributes.author_id}`,
                    current_user_id: `${transientStorageManager?.currentUser?.id}`,
                    status: `${attributes.pregenerated_minutes}`,
                    view_toast: attributes.view_toast,
                    statusMessage:
                        attributes.pregenerated_minutes ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('UNAPPROVED_STATUS', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('AI_GENERATE_SUCCESS_DESCRIPTION', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.pregenerated_minutes ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_GENERATE_PARTIAL_FAIL_SUBTITLE', {
                                  minutesTitle: attributes.title
                              })
                            : i18n.t('AI_GENERATE_FAIL_SUBTITLE', {
                                  minutesTitle: attributes.title
                              })
                }))
            this.setState({ toasts: newToasts })

            newToasts.forEach((toast) => {
                if (toast.id !== this.props.currentMinuteItem?.id) {
                    setTimeout(() => {
                        this.setState((prevState: any) => ({
                            toasts: prevState.toasts.filter((t) => t.id !== toast.id)
                        }))
                        if (
                            toast?.id !== this.props.currentMinuteItem?.id &&
                            toast.view_toast === true
                        ) {
                            updatePregenerationAIMinutes(toast.id, toast.status, false)
                        } else if (
                            toast?.id === this.props.currentMinuteItem?.id &&
                            toast.pregenerated_minutes === PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                        ) {
                            if (toast.view_toast === true) {
                                updatePregenerationAIMinutes(toast.id, toast.status, false)
                            }
                        }
                    }, 10000)
                }
            })
            // Filter pregenDocumentsData to show only non 'progress' toasts
            const newTranscriptToasts = transcriptData
                .filter(
                    ({ attributes }) =>
                        attributes.transcript_status !== PREGENERATION_DOCUMENT_STATUS.PROGRESS
                )
                .map(({ attributes }) => ({
                    id: `${attributes.document_id}`,
                    MinutesTitle:
                        attributes.transcript_status ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('AI_GENERATE_FAIL_TITLE')
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('MEETING_MINUTES_READY')
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_TTM_GENERATE_PARTIAL_FAIL_TITLE')
                            : i18n.t('FAILED_TO_GENERATE'),
                    author_id: `${attributes.author_id}`,
                    current_user_id: `${transientStorageManager?.currentUser?.id}`,
                    status: `${attributes.transcript_status}`,
                    view_toast: attributes.view_toast,
                    statusMessage:
                        attributes.transcript_status ===
                        PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR
                            ? i18n.t('UNAPPROVED_STATUS', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                            ? i18n.t('AI_TTM_GENERATE_SUCCESS_SUBTITLE', {
                                  minutesTitle: attributes.title
                              })
                            : attributes.transcript_status ===
                              PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS
                            ? i18n.t('AI_TTM_GENERATE_PARTIAL_FAIL_SUBTITLE', {
                                  minutesTitle: attributes.title
                              })
                            : i18n.t('AI_TTM_GENERATE_FAIL_SUBTITLE', {
                                  minutesTitle: attributes.title
                              })
                }))
            this.setState({ toastsTranscript: newTranscriptToasts })

            newTranscriptToasts.forEach((toast) => {
                if (toast.id !== this.props.currentMinuteItem?.id) {
                    setTimeout(() => {
                        this.setState((prevState: any) => ({
                            toastsTranscript: prevState.toastsTranscript.filter(
                                (t) => t.id !== toast.id
                            )
                        }))
                        if (
                            toast?.id !== this.props.currentMinuteItem?.id &&
                            toast.view_toast === true
                        ) {
                            updateTranscriptAIMinutes(toast.id, toast.status, false)
                        } else if (
                            toast?.id === this.props.currentMinuteItem?.id &&
                            toast.pregenerated_minutes === PREGENERATION_DOCUMENT_STATUS.AUTOMATED
                        ) {
                            if (toast.view_toast === true) {
                                updateTranscriptAIMinutes(toast.id, toast.status, false)
                            }
                        }
                    }, 10000)
                }
            })
        } catch (error) {
            this.setState({ error: 'Error fetching data', loading: false })
        }
    }

    scrollToLastListItem = () => {
        //Note we scroll to last added item (only once per new added item)
        if (!this.lastListItem || !this.list || this.isScrollingToLastItem) {
            return
        }
        this.isScrollingToLastItem = true

        const lastItem = ReactDOM.findDOMNode(this.lastListItem)

        if (!(lastItem instanceof HTMLElement)) {
            return
        }

        const list = ReactDOM.findDOMNode(this.list) as Element

        if (lastItem && list && lastItem.offsetTop) {
            list.scrollTop = lastItem.offsetTop
            setTimeout(() => (this.isScrollingToLastItem = false), 50)
        }
    }

    //This function return actuial node by id. It is required for the scroller.
    getListNodeById = (id) => {
        if (!this.allListNodes || !id) {
            return null
        }

        return this.allListNodes.get(id)
    }

    onResize = (evt) => {
        this.setState({ height: evt.height - headerHeight })
    }

    handleEditAction = (actionId) => {
        // this.props.actions.setGlobalFocusLockState(true)
        this.props.actions.editAction({ action: { actionId: actionId }, block: null })
    }

    handleDeleteAction = async (actionId) => {
        const minutesId = this.props.currentMinuteItem.id
        await deleteAction(minutesId, actionId, deleteAction.bind(null, minutesId, actionId))

        this.props.actions.removeAction(actionId)

        const minutesDetails = await getMinutesDetail(minutesId, null, true)
        this.props.actions.fetchMinuteItemFulfilled(minutesDetails)
    }

    getPregeneratedMinutes = async (status, forTranscript = false) => {
        const minutesId = this.props.currentMinuteItem.id
        try {
            const minutesDetails = await getMinutesDetail(minutesId, null, true)
            const pregeneratedMinutes =
                minutesDetails.minutesDocuments[minutesId].attributes.pregeneratedMinutes
            this.setState({ pregeneratedMinutes, loaded: true })
            if (forTranscript) {
                const transcriptedMinutes =
                    minutesDetails.minutesDocuments[minutesId].attributes.transcript_status
                this.setState({ transcriptedMinutes, loaded: true })
            }

            const sectionsDetails = await getSections(minutesId)
            if (
                this.props.minutesSections?.length === 1 &&
                Object.keys(sectionsDetails.minutesSections).length === 1 &&
                (pregeneratedMinutes !== PREGENERATION_DOCUMENT_STATUS.PROGRESS ||
                    status === PREGENERATION_DOCUMENT_STATUS.CANCELED ||
                    status === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ||
                    status === PREGENERATION_DOCUMENT_STATUS.FAILED)
            ) {
                this.props.actions.addSection()
            }
        } catch (error) {
            this.setState({ loaded: true })
        }
    }

    handleViewAllActions = (actionId) => {
        this.props.history.push(`/taker/${this.props.currentMinuteItem.id}/actions/${actionId}`, {
            actionId
        })
        this.props.actions.selectActionItem(actionId)
    }

    handleSectionReorder = (drag, hover) => {
        this.props.actions.changeSectionOrder(drag, hover)
    }

    createEditorItem = (
        list: Array<JSX.Element>,
        key: any,
        id: string,
        order: number,
        totalSections: number,
        isReadonlyDocument: boolean,
        count: number
    ) => {
        const ref = (c) => {
            this.lastListItem = c
            if (!c || !c.props) {
                return
            }
            this.allListNodes.set(c.props.sectionId, c)
        }

        const sectionActionItems = this.props.actionItems
            ? this.props.actionItems.filter((action) => {
                  return action.minutesSectionId === +id
              })
            : []

        //Sort section actions by sortOrder
        sectionActionItems.sort((a, b) => {
            if (a.sortOrder > b.sortOrder) return 1
            if (a.sortOrder < b.sortOrder) return -1

            return 0
        })

        const element = (
            <Draggable
                key={key}
                draggableId={key}
                index={count}
                direction="vertical"
                bounds="parent">
                {(provided, snapshot) => {
                    //for vertical movement while dragging
                    var transform = provided.draggableProps.style.transform
                    if (transform) {
                        var t = transform.split(',')[1]
                        var newStyle = {
                            ...provided.draggableProps.style,
                            transform: `translate(0px, ${t}`
                        }
                        provided.draggableProps.style = newStyle
                    }
                    return (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <EditorListItem
                                ref={ref}
                                key={key}
                                originalKey={key}
                                sectionId={id}
                                order={order}
                                totalCount={totalSections}
                                readonly={isReadonlyDocument}
                                onSectionReorder={this.handleSectionReorder}
                                actionItems={sectionActionItems}
                                onEditAction={this.handleEditAction}
                                onDeleteAction={this.handleDeleteAction}
                                onActionChecked={this.props.onActionChecked}
                                onViewAllActions={this.handleViewAllActions}
                                dragProps={provided.dragHandleProps}
                                snapshot={snapshot}
                            />
                        </div>
                    )
                }}
            </Draggable>
        )

        list.push(element)

        //If we have new item added its key is guid and so NaN
        //We then can scroll to it
        //We also store all scrolled to new item in a map
        //To scroll only once to those new items
        if (isNaN(key) && !this.scrolledNewItems.get(key)) {
            this.scrolledNewItems.set(key, true)
            setTimeout(() => this.scrollToLastListItem(), 50)
        }
    }

    getElementIdAndKey = (element) => {
        const id = element.id || element.tempId
        const key = element.tempId || element.id

        return {
            id,
            key
        }
    }
    getEditorItems = (isReadonlyDocument: boolean) => {
        const { currentMinuteItem, minutesSections } = this.props

        if (!currentMinuteItem || !minutesSections) {
            return null
        }

        let result: Array<JSX.Element> = []
        let count = 0
        for (var i in minutesSections) {
            if (minutesSections.hasOwnProperty(i)) {
                //Each list item has title and body. Body has section content.
                var element = minutesSections[i]
                if (element.sectionType !== 'minutes') {
                    continue
                }

                const { id, key } = this.getElementIdAndKey(element)

                //NOTE: Trick here is to use OLD tempId as key if available
                //That is to prevent items being destroyed as soon as new item is saved
                //We also pass original key attribute so underlying keyed controls could
                //use the same key before and after first save not destroying the original editor
                if (element.loaded) {
                    this.createEditorItem(
                        result,
                        key,
                        id,
                        element.order,
                        minutesSections.length,
                        isReadonlyDocument,
                        count
                    )
                } else {
                    //Note: loading all items at once is time consuming task
                    //because parsing server data takes time for each editor
                    //Parsing JSON into Draft JS is basically intrinsically slow
                    //So we load items one by one marking them loaded = false when
                    //we get them from server and then processing it here one by one
                    this.parseSectionData(element, minutesSections.length)
                    return result
                }
            }
            count++
        }
        return result
    }

    parseSectionData = (section, totalSectionsCount) => {
        //All this function does so far is marks items as loaded every X msec one by one
        //Then updates reducer so it has more "loaded" items and so it it would keep rendering
        //them in serial way
        setTimeout(() => {
            //Short breaks for smaller count of sections
            this.props.actions.parseSectionData(section)
        }, Math.min(100, totalSectionsCount * 5))
    }

    renderLoadingProgress = (minutesSections: Array<any>): JSX.Element | null => {
        if (minutesSections) {
            //We showing loading indicator
            if (minutesSections.find((c) => !c.loaded)) {
                return <LinearIndeterminate loading={true} />
            }
        }
        return null
    }
    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        this.props.actions.changeSectionOrder(result.source.index + 1, result.destination.index + 1)
    }
    onDragStart = () => {
        document.querySelectorAll('.ai-modal').forEach((it: any) => (it.style.display = 'none'))
    }

    updateGeneration = async (status: string) => {
        const minutesId = this.props.currentMinuteItem.id
        await updatePregenerationAIMinutes(minutesId, status)
        await this.getPregeneratedMinutes(status)
        if (status === 'acknowledged' || status === 'canceled') {
            this.setState({ pregeneratedMinutes: status })
        }
    }

    updateTranscriptGeneration = async (status: string) => {
        const minutesId = this.props.currentMinuteItem.id
        await updateTranscriptAIMinutes(minutesId, status)
        await this.getPregeneratedMinutes(status, true)
        if (status === 'acknowledged' || status === 'canceled') {
            this.setState({ transcriptedMinutes: status })
        }
    }
    updateNavigateOut = (state) => {
        this.setState({ navigatingOut: state })
    }

    handleGenerationState = (state: any) => {
        switch (state) {
            case 'progress':
                return (
                    <AiGenerateDialog
                        updateGeneration={this.updateGeneration}
                        updateNavigateOut={this.updateNavigateOut}
                        pregeneratedMinutes={this.state.pregeneratedMinutes}
                    />
                )
            case 'automated':
                return (
                    <AiGenerationCompleted
                        updateGeneration={this.updateGeneration}
                        pregeneratedMinutes={this.state.pregeneratedMinutes}
                        pregenerationStatus={this.state.pregenerationStatus}
                    />
                )
            default:
                return null
        }
    }

    handleTranscriptGenerationState = (state: any) => {
        if (state === PREGENERATION_DOCUMENT_STATUS.AUTOMATED) {
            this.setState({ showAiNotificationBar: true })
            return null
        }
        this.setState({ showAiNotificationBar: false })
        return null
    }

    updateFileDetailsAndStatus = (items, status) => {
        this?.setState({
            items: items,
            uploadStatus: status,
            fileUploadError: ''
        })
    }

    uploadFileForTranscript = async () => {
        try {
            const minutesId = this.props.currentMinuteItem.id
            let response = await fetchFileUploadResponse(
                minutesId,
                this.state.items?.file ? [this.state.items.file] : []
            )

            if (response?.error) {
                this.setState({
                    fileUploadError: response.error,
                    uploadStatus: TRANSCRIPT_UPLOAD_STATUS.FAILED
                })
            } else if (response?.data) {
                this.setState({
                    transcriptId: response.data[0]?.id,
                    uploadStatus: TRANSCRIPT_UPLOAD_STATUS.SUCCESS
                })
            } else {
                this.setState({
                    fileUploadError: 'Error uploading file',
                    uploadStatus: TRANSCRIPT_UPLOAD_STATUS.FAILED
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    deleteFileForTranscript = async (transcriptId) => {
        try {
            const minutesId = this.props.currentMinuteItem.id
            await fetchFileDeleteResponse(minutesId, transcriptId)
            this?.setState({
                transcriptId: '',
                fileUploadError: ''
            })
        } catch (error) {
            console.log(error)
        }
    }

    handleGenerateTranscript = async () => {
        const minutesId = this.props.currentMinuteItem.id
        const committeeId = this.props.currentMinuteItem?.relationships?.committee?.data?.id

        try {
            await generateTranscript(
                minutesId,
                committeeId,
                JSON.stringify({
                    transcript_ids: [JSON.parse(this.state.transcriptId)]
                })
            )
        } catch (error) {
            console.error('Error generating transcript:', error)
        }
        return Promise.resolve()
    }

    handleOpenUploadModal = (uploadModal) => {
        this?.setState(() => ({ uploadModal: !uploadModal }))
    }

    handleOpenTtmModal = async () => {
        this.handleOpenUploadModal(this.state.uploadModal)
        this?.setState(() => ({ transcriptedMinutes: TRANSCRIPT_UPLOAD_STATUS.PROGRESS }))
        this.updateFileDetailsAndStatus(
            { name: '', size: 0, file: null },
            TRANSCRIPT_UPLOAD_STATUS.DEFAULT
        )
        await this.handleGenerateTranscript()
        this.startPolling()
    }

    handleSelectTranscriptFile = async (event) => {
        const file = event.target.files[0]
        if (!file) return

        // Show progress
        this.setState({
            uploadStatus: TRANSCRIPT_UPLOAD_STATUS.PROGRESS
        })

        const MAX_FILE_SIZE = 1.5 * 1024 * 1024 // 1.5 MB
        const MAX_WORD_COUNT = 130000

        if (file.size > MAX_FILE_SIZE) {
            this.setState({
                items: { name: file.name, size: file.size, file },
                uploadStatus: TRANSCRIPT_UPLOAD_STATUS.FAILED
            })
            return
        }

        const reader = new FileReader()
        reader.onload = async (e) => {
            const content = e.target?.result as string
            const wordCount = content.match(/\b\w+\b/g)?.length || 0
            if (wordCount > MAX_WORD_COUNT) {
                this.setState({
                    items: { name: file.name, size: file.size, file },
                    uploadStatus: TRANSCRIPT_UPLOAD_STATUS.FAILED
                })
                return
            }

            // Proceed with the upload
            this.setState({
                items: { name: file.name, size: file.size, file },
                uploadStatus: TRANSCRIPT_UPLOAD_STATUS.PROGRESS // Show spinner
            })

            await this.uploadFileForTranscript()
        }
        reader.readAsText(file)
    }
    dismissAiNotificationBar = () => {
        this.setState({ showAiNotificationBar: false })
    }

    render() {
        const {
            minutesSections,
            currentMinuteItem,
            currentSelectedTab,
            isEditingAction
        } = this.props

        let progress: JSX.Element | null = this.renderLoadingProgress(minutesSections)

        const preogressHeightCorrection = progress ? progressContainerSize : 0
        const rootHeight =
            this.state.height - preogressHeightCorrection - toolbarSize - newToolbarSize

        const documentStatus = pathOr(
            '',
            ['attributes', 'status'],
            currentMinuteItem
        ) as DocumentStatusKey

        const isReadonlyDocument =
            documentStatus === DOCUMENT_STATUS.FINAL_DRAFT ||
            documentStatus === DOCUMENT_STATUS.APPROVED
        const allItems = this.state.loaded && this.getEditorItems(isReadonlyDocument)
        const isMinutesLoading = () =>
            minutesSections ? minutesSections.find((c) => !c.loaded) : false

        // Required to remmount Action Dialog every time we use it in order to
        // reference the correct Action Item
        const actionItemDialog = isEditingAction ? <ActionItemEditDialog /> : null
        const { pregeneratedMinutes, pregenerationStatus } = this.state
        const aiGenerateBookSummary = this.handleGenerationState(
            pregeneratedMinutes || pregenerationStatus
        )
        const aiTranscriptGenerateSummary = this.handleTranscriptGenerationState(
            this.state.transcriptedMinutes || this.state.transcriptStatus
        )
        const handletakerScreenNotification = (
            toast: any,
            currentMinuteItemId: any,
            forTranscript: boolean
        ) => {
            if (toast?.id === currentMinuteItemId) {
                return (
                    <UtilityHandleToast
                        toast={toast}
                        documentStatus={documentStatus}
                        forTranscript={forTranscript}
                        removeToast={this.removeToast}
                        currentMinuteItem={this.props.currentMinuteItem}
                        updateTranscriptGeneration={this.updateTranscriptGeneration}
                        updateGeneration={this.updateGeneration}
                    />
                )
            }
            return null
        }

        if (this.state.navigatingOut) {
            return (
                <Redirect
                    to={{
                        pathname: '/',
                        state: {}
                    }}
                />
            )
        }

        return (
            <StyleWrapper height={rootHeight}>
                <Toolbar disabled={isMinutesLoading()}>
                    {(toolbarProps) => (
                        <DocumentToolbar
                            onStatusChange={this.props.onStatusChange}
                            isDocumentReadOnly={isReadonlyDocument}
                            {...toolbarProps}
                        />
                    )}
                </Toolbar>
                <EditorToolbar
                    currentMinuteItem={currentMinuteItem}
                    onStatusChange={this.props.onStatusChange}
                />
                {progress}
                {currentSelectedTab === EDITOR_TABS.ATTENDEES && (
                    <Attendees readonly={isReadonlyDocument} />
                )}

                {currentSelectedTab === EDITOR_TABS.EDITOR && (
                    <AutoScroller items={allItems} getListNodeById={this.getListNodeById}>
                        <RootRef rootRef={(c) => (this.list = c)}>
                            <List id="list-scroll">
                                {aiGenerateBookSummary}
                                {aiTranscriptGenerateSummary}

                                {this.state.showAiNotificationBar && (
                                    <TtmGeneratedNotification
                                        updateTranscriptGeneration={this.updateTranscriptGeneration}
                                        dismissAiNotificationBar={this.dismissAiNotificationBar}
                                    />
                                )}

                                <ListWrapper>
                                    <ListSubheader className={listSubheader}>
                                        <Box className={ToolbarBoxCss}>
                                            <Box
                                                className={ToolbarCss}
                                                role="toolbar"
                                                aria-label="Editor Toolbar">
                                                {documentStatus === DOCUMENT_STATUS.DRAFT && (
                                                    <AddSectionButton
                                                        showLabels={true}
                                                        onClick={this.props.actions.addSection}
                                                        aria-label={i18n.t('ADD_SECTION_ITEM')}
                                                    />
                                                )}
                                                {documentStatus === DOCUMENT_STATUS.DRAFT && (
                                                    <AddActionButton
                                                        showLabels={true}
                                                        onClick={this.props.actions.addAction}
                                                        aria-label={i18n.t('ADD_ACTION_ITEM')}
                                                    />
                                                )}
                                                {transientStorageManager.book_summarization_enabled !==
                                                    null && (
                                                    <AddUploadButton
                                                        showLabels={true}
                                                        onClick={() => {
                                                            this.handleOpenUploadModal(
                                                                this.state.uploadModal
                                                            )
                                                        }}
                                                        summarizationEnabled={
                                                            transientStorageManager.book_summarization_enabled &&
                                                            documentStatus === DOCUMENT_STATUS.DRAFT
                                                        }
                                                        aria-label={i18n.t(
                                                            'AI_TTM_IMPORT_TRANSCRIPT'
                                                        )}
                                                    />
                                                )}
                                            </Box>
                                            <Box>
                                                <Toolbar>
                                                    {(toolbarProps) =>
                                                        minutesSections?.length > 1 ? (
                                                            <UtilityExpandCollapse
                                                                toggleExpandCollapseAll={
                                                                    toolbarProps.actions
                                                                        .toggleExpandCollapseAll
                                                                }
                                                            />
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                </Toolbar>
                                            </Box>
                                        </Box>
                                    </ListSubheader>
                                    <DragDropContext
                                        onDragEnd={this.onDragEnd}
                                        onDragStart={this.onDragStart}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}>
                                                    {allItems}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <Footer
                                        sectionCount={allItems ? allItems.length : 0}
                                        onSectionReorder={this.handleSectionReorder}
                                    />
                                </ListWrapper>
                            </List>
                        </RootRef>
                    </AutoScroller>
                )}
                {currentSelectedTab === EDITOR_TABS.ACTIONS && (
                    <MinutesActions setIsLoading={this.props.setIsLoading} />
                )}
                {actionItemDialog}
                <ToastContainer>
                    {this.state.toasts.map((toast: any) =>
                        handletakerScreenNotification(
                            toast,
                            this.props?.currentMinuteItem?.id,
                            false
                        )
                    )}
                    {this.state.toastsTranscript.map((toast: any) =>
                        handletakerScreenNotification(
                            toast,
                            this.props?.currentMinuteItem?.id,
                            true
                        )
                    )}
                </ToastContainer>
                {this.state.uploadModal && (
                    <TtmUploadDialog
                        updateFileDetailsAndStatus={this.updateFileDetailsAndStatus}
                        handleOpenUploadModal={this.handleOpenUploadModal}
                        handleOpenTtmModal={this.handleOpenTtmModal}
                        handleSelectTranscriptFile={this.handleSelectTranscriptFile}
                        uploadModal={this.state.uploadModal}
                        uploadStatus={this.state.uploadStatus}
                        items={this.state.items}
                        deleteFileForTranscript={this.deleteFileForTranscript}
                        transcriptId={this.state.transcriptId}
                        fileUploadError={this.state.fileUploadError}
                    />
                )}
                <TtmGenerateDialog
                    updateTranscriptGeneration={this.updateTranscriptGeneration}
                    updateNavigateOut={this.updateNavigateOut}
                    transcriptedMinutes={this.state.transcriptedMinutes}
                />
                <DomHelper onResize={this.onResize} />
            </StyleWrapper>
        )
    }
}
//#endregion

//#region Export / Redux Bindings

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer),
        minutesSections: selectors.minutesSections(state.minuteTakerReducer),
        selectedTabIndex: selectors.selectedTabIndex(state.minuteTakerReducer),
        actionItems: selectors.minutesActions(state.minuteTakerReducer),
        isEditingAction: selectors.isEditingAction(state.minuteTakerReducer),
        currentSelectedTab: selectors.currentSelectedTab(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    const {
        parseSectionData,
        addAction,
        addSection,
        loadSections,
        editAction,
        removeAction,
        toggleExpandCollapseAll,
        selectActionItem,
        changeSectionOrder,
        loadActionsFulfilled,
        fetchMinuteItemFulfilled
    } = {
        ...actions,
        ...actionEditorReducer
    }

    return {
        actions: bindActionCreators(
            {
                parseSectionData,
                addAction,
                addSection,
                loadSections,
                editAction,
                removeAction,
                toggleExpandCollapseAll,
                selectActionItem,
                changeSectionOrder,
                loadActionsFulfilled,
                fetchMinuteItemFulfilled
            },
            dispatch
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MinuteEditorListView))
//#endregion
