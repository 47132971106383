import React from 'react'
import styled from '@emotion/styled'

const Svg = styled('svg')``

type Props = {
    title?: JSX.Element
    className?: string
}

const ErrorIcon = ({ className }: Props) => (
    <Svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.0475 11.901V5.61524C10.0475 4.92094 10.6103 4.3581 11.3046 4.3581C11.9989 4.3581 12.5618 4.92094 12.5618 5.61524V11.901C12.5618 12.5953 11.9989 13.1581 11.3046 13.1581C10.6103 13.1581 10.0475 12.5953 10.0475 11.901ZM12.9173 16.3429C12.9173 17.2337 12.1955 17.9562 11.3046 17.9562C10.4138 17.9562 9.69194 17.2337 9.69194 16.3429C9.69194 15.452 10.4138 14.7295 11.3046 14.7295C12.1955 14.7295 12.9173 15.452 12.9173 16.3429ZM0 11C0 4.9248 4.92438 0 11 0C17.0748 0 22 4.92522 22 11C22 17.0748 17.0748 22 11 22C4.92438 22 0 17.0752 0 11ZM20.1143 11C20.1143 5.96667 16.0333 1.88571 11 1.88571C5.96586 1.88571 1.88571 5.96622 1.88571 11C1.88571 16.0338 5.96586 20.1143 11 20.1143C16.0333 20.1143 20.1143 16.0333 20.1143 11Z"
            fill="#AF292E"
        />
    </Svg>
)

export default ErrorIcon
